/* eslint-disable @next/next/no-img-element */
import { clsx } from "clsx";
import { Icon } from "components/shared";
import Image from "next/legacy/image";
import { useStudentBrowserData } from "../../StudentBrowserProvider";

type Props = { tabIndex: number };

export const StudentBrowserTab = ({ tabIndex }: Props) => {
  const { tabs, setTabs, isActiveTab, activeTabIndex, setActiveTabIndex } =
    useStudentBrowserData();
  const tab = tabs[tabIndex];

  const getTabTextColor = (tabIndex: number) =>
    isActiveTab(tabIndex) ? "text-gray-600" : "text-white";

  return (
    <div
      onClick={() => setActiveTabIndex(tabIndex)}
      className={clsx(
        "flex relative items-center justify-start pb-[5px]",
        "rounded-t-lg border-b-0 gap-x-1 px-2 overflow-hidden",
        "text-md font-semibold w-auto h-full max-w-[145px]!",
        "cursor-pointer border border-slate-200",
        tab.specialTab === "home"
          ? "min-w-[42px]"
          : tab.specialTab === "search"
          ? "min-w-[70px]"
          : "min-w-[110px]",
        isActiveTab(tabIndex)
          ? "bg-white border-slate-400"
          : "bg-slate-600 hover:bg-slate-500 border-slate-700",
        getTabTextColor(tabIndex)
      )}
    >
      {tab.iconUrl && (
        <Image
          src={`/logos/videoClientLogos/${tab.iconUrl}`}
          width={24}
          alt="logo"
          height={24}
        />
      )}
      {tab.icon && (
        <Icon icon={tab.icon} size={6} color={getTabTextColor(tabIndex)} />
      )}
      {tab.specialTab === "search" && (
        <Image
          src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
          alt="Google Logo"
          width={24}
          height={24}
        />
      )}
      {tab.name && (
        <span className="flex relative h-full items-center w-fit mr-6 truncate">
          {tab.name}
        </span>
      )}

      {tab.specialTab !== "home" && activeTabIndex !== tabIndex && (
        <>
          <div className="w-[25px] h-full absolute right-0 top-0 bg-slate-600 group-hover:bg-slate-500" />
          <div className="w-[40px] h-full absolute right-[25px] top-0 bg-linear-to-l from-slate-600 via-transparent to-transparent group-hover:from-slate-500" />
        </>
      )}

      {tab.specialTab !== "home" && (
        <div
          className="absolute flex h-full w-[32px] group bg-none flex-center cursor-pointer right-0"
          onClick={(e) => {
            e.stopPropagation();
            const tabsLen = tabs.length;
            // Set new active tab upon active tab removal
            if (activeTabIndex === tabIndex) setActiveTabIndex(tabIndex - 1); // switch to previous tab (tab-left)
            if (activeTabIndex === tabsLen - 1) setActiveTabIndex(tabsLen - 2); // switch to new last tab (tab-right)
            if (tabsLen === 2) setActiveTabIndex(0); // Switch to the home tab if there are only two tabs
            setTabs(tabs.filter((__, index) => index !== tabIndex));
          }}
        >
          <Icon
            icon="remove"
            size={5}
            color={clsx(
              isActiveTab(tabIndex)
                ? "text-slate-600 hover:text-rose-500"
                : "text-white group-hover:text-rose-500"
            )}
          />
        </div>
      )}
    </div>
  );
};
