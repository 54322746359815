import { VideoClientLink } from "@generated/graphql";
import { HEADER_HEIGHT } from "sections/ZoomClient/constants";
import { useSplitPaneData } from "../SplitPane/SplitPaneProvider";
import { StudentBrowserHeader } from "./components/StudentBrowserHeader";
import { HomeTab } from "./components/StudentBrowserHeader/HomeTab";
import { StudentBrowserIFrame } from "./components/StudentBrowserIFrame";
import {
  StudentBrowserProvider,
  useStudentBrowserData,
} from "./components/StudentBrowserProvider";
import { StudentBrowserTabContainer } from "./components/StudentBrowserTabContainer";

type Props = {
  quickLinks: VideoClientLink[];
  showGoogleSearch: boolean;
};

export const StudentBrowserPanel = ({
  quickLinks,
  showGoogleSearch,
}: Props) => {
  return (
    <StudentBrowserProvider>
      <StudentBrowserBody
        quickLinks={quickLinks}
        showGoogleSearch={showGoogleSearch}
      />
    </StudentBrowserProvider>
  );
};

const StudentBrowserBody = ({ quickLinks, showGoogleSearch }: Props) => {
  const { tabs, isActiveTab } = useStudentBrowserData();
  const { height } = useSplitPaneData();
  const effectiveHeight = (height ?? 0) - HEADER_HEIGHT;

  const renderOtherTabs = tabs.map((tab, i) => (
    <StudentBrowserTabContainer
      key={i}
      effectiveHeight={effectiveHeight}
      zIndex={isActiveTab(i) ? 1100 : 990 - i * 10}
    >
      {tab.specialTab === "home" || tab.specialTab === "search" ? (
        <HomeTab showGoogleSearch={showGoogleSearch} quickLinks={quickLinks} />
      ) : (
        <StudentBrowserIFrame
          title="Embedded Content"
          src={tab.url}
          effectiveHeight={effectiveHeight}
        />
      )}
    </StudentBrowserTabContainer>
  ));

  return (
    <div className="flex flex-col gap-y-0 h-full w-full overflow-hidden bg-white z-0">
      <div className="relative" style={{ height: `${HEADER_HEIGHT}px` }}>
        <StudentBrowserHeader />
      </div>
      <div className="relative">{renderOtherTabs}</div>
    </div>
  );
};
