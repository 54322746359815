import { ZoomClientConfiguration } from "@generated/graphql";
import dynamic from "next/dynamic";
import { StudentZoomPanelProps } from "../types";
import { SplitPane } from "./SplitPane";
import { StudentBrowserPanel } from "./StudentBrowserPanel";

const StudentZoomPanel = dynamic<StudentZoomPanelProps>(
  () =>
    import("sections/ZoomClient/StudentZoomClientPage/StudentZoomPanel").then(
      (mod) => mod.StudentZoomPanel
    ),
  { ssr: false }
);

type Props = {
  previewConfig?: { width: number; height: number };
  zoomConfig: ZoomClientConfiguration;
};

export const StudentZoomClientPage = ({ zoomConfig, previewConfig }: Props) => (
  <div
    className="relative flex z-0"
    style={{
      width: previewConfig?.width ?? "100vw",
      height: previewConfig?.height ?? "100vh",
    }}
  >
    <SplitPane
      initialLeftPaneWidthPercentage={zoomConfig.initialZoomWidthPercent}
      leftPane={<StudentZoomPanel previewMode={!!previewConfig} />}
      rightPane={
        <StudentBrowserPanel
          showGoogleSearch={zoomConfig.showGoogleSearch}
          quickLinks={zoomConfig.videoClientLinks}
        />
      }
    />
  </div>
);
