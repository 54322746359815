import { Pixel } from "@utils/numbers";
import noop from "lodash/noop";
import React from "react";

type SplitPaneContextProps = {
  leftWidth: Pixel | undefined;
  rightWidth: Pixel | undefined;
  totalWidth: Pixel | undefined;
  height: Pixel | undefined;
  setLeftWidth: React.Dispatch<React.SetStateAction<Pixel | undefined>>;
  setRightWidth: React.Dispatch<React.SetStateAction<Pixel | undefined>>;
  setTotalWidth: React.Dispatch<React.SetStateAction<Pixel | undefined>>;
  setHeight: React.Dispatch<React.SetStateAction<Pixel | undefined>>;
};

const initialState: SplitPaneContextProps = {
  leftWidth: undefined,
  rightWidth: undefined,
  totalWidth: undefined,
  height: undefined,
  setLeftWidth: noop,
  setRightWidth: noop,
  setTotalWidth: noop,
  setHeight: noop,
};

export const SplitPaneContext =
  React.createContext<SplitPaneContextProps>(initialState);

type Props = {
  children: React.ReactNode;
};

export const SplitPaneProvider = ({ children }: Props) => {
  const [leftWidth, setLeftWidth] = React.useState<Pixel | undefined>(
    undefined
  );
  const [rightWidth, setRightWidth] = React.useState<Pixel | undefined>(
    undefined
  );
  const [totalWidth, setTotalWidth] = React.useState<Pixel | undefined>(
    undefined
  );
  const [height, setHeight] = React.useState<Pixel | undefined>(undefined);

  return (
    <SplitPaneContext.Provider
      value={{
        leftWidth,
        rightWidth,
        totalWidth,
        height,
        setLeftWidth,
        setRightWidth,
        setTotalWidth,
        setHeight,
      }}
    >
      {children}
    </SplitPaneContext.Provider>
  );
};

export const useSplitPaneData = (): SplitPaneContextProps => {
  const context = React.useContext(SplitPaneContext);
  if (!context)
    throw new Error("useSplitPaneData must be used within a SplitPaneProvider");

  return context;
};
