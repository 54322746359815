import { SelectMenuOption } from "components/shared";
import { IconType } from "components/shared/Icon";

export type StudentZoomPanelProps = {
  previewMode?: boolean;
};

export type StudentZoomComponentProps = {
  meetingId: string;
};

export type BrowserTab = {
  specialTab?: "home" | "search";
  url?: string;
  name?: string;
  icon?: IconType;
  iconUrl?: string | null;
};

export type BrowserTabOption = SelectMenuOption<{
  index: number;
}>;

export enum VideoClientLinkAction {
  Edit = "circleEdit",
  Remove = "remove",
  Add = "add",
}

export enum ZoomConfigMode {
  Default = "Default",
  New = "New",
  Edit = "Edit",
}
