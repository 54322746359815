import { exitFullScreen, requestFullScreen } from "@utils/browser";

export const toggleFullScreen = async (
  isFullScreen: boolean
): Promise<boolean> => {
  if (isFullScreen) {
    try {
      return !(await exitFullScreen());
    } catch (_) {
      // Failed to exit fullscreen, likely means we're NOT in fullscreen.
      return false;
    }
  } else {
    return await requestFullScreen();
  }
};
