import { BrowserTab, BrowserTabOption } from "sections/ZoomClient/types";

export const getTabListOptions = (tabs: BrowserTab[]): BrowserTabOption[] => {
  const getTabName = (tab: BrowserTab): string => {
    if (tab.name) {
      return tab.name.length > 30
        ? `${tab.name.substring(0, 20)}...`
        : tab.name;
    } else if (tab.specialTab === "home") {
      return "Home";
    } else if (tab.specialTab === "search") {
      return "Search";
    } else {
      return "Tab";
    }
  };

  return tabs
    .map((tab, i) => ({
      id: String(i),
      value: getTabName(tab),
      index: i,
    }))
    .reverse();
};
