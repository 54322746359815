import {
  MINUTE_MS,
  floatingToZonedDateTime,
  getTimeLeft,
} from "@utils/dateTime";

export const getNumberFromString = (str: string): number =>
  parseInt(str.replace(/,/g, ""), 10);

export const getEarlyBannerDetails = (
  dismissBanner: boolean,
  start: Date,
  timeZone?: string
) => {
  if (timeZone && !dismissBanner) {
    const now = new Date();
    const localStartTime = floatingToZonedDateTime(new Date(start), timeZone);
    const diff = localStartTime.getTime() - now.getTime();
    const diffMinutes = diff / MINUTE_MS;
    if (diffMinutes > 15) {
      return {
        showBanner: true,
        diffString: getTimeLeft(diff),
        tryAgainIn:
          diffMinutes < 120 ? getTimeLeft(diff - 15 * MINUTE_MS) : undefined,
      };
    }
  }
  return { showBanner: false, diffString: "", tryAgainIn: "" };
};
