import { VideoClientLink } from "@generated/graphql";
import { openInNewTab } from "@utils/browser";
import { useSplitPaneData } from "sections/ZoomClient/StudentZoomClientPage/SplitPane/SplitPaneProvider";
import { VideoClientHomeLink } from "sections/ZoomClient/components/VideoClientHomeLink";
import { useStudentBrowserData } from "../../../StudentBrowserProvider";

type Props = {
  quickLinks: VideoClientLink[];
  showIcon?: boolean;
};

export const HomeQuickLinks = ({ quickLinks, showIcon = true }: Props) => {
  const { tabs, setTabs, setActiveTabIndex } = useStudentBrowserData();
  const { rightWidth } = useSplitPaneData();

  const externalClick = (link: VideoClientLink) => {
    openInNewTab(link.url);
  };
  const newTabClick = (link: VideoClientLink) => {
    setTabs((prev) => [
      ...prev,
      {
        url: link.url,
        name: link.name,
        iconUrl: link.iconPath,
      },
    ]);
    setActiveTabIndex(tabs.length);
  };

  return (
    <div
      className="grid grid-cols-2 gap-3 w-full max-w-[800px]"
      style={{ width: `${(rightWidth ?? 0) - 40}px` }}
    >
      {quickLinks.map((link, i) => (
        <VideoClientHomeLink
          key={i}
          link={link}
          onClick={
            link.forceExternal
              ? () => externalClick(link)
              : () => newTabClick(link)
          }
          showIcon={showIcon}
        />
      ))}
    </div>
  );
};
