import { clsx } from "clsx";
import { Icon, SelectMenu } from "components/shared";
import { useStudentData } from "contexts/StudentDataProvider";
import { useEffect, useRef, useState } from "react";
import { useSplitPaneData } from "sections/ZoomClient/StudentZoomClientPage/SplitPane/SplitPaneProvider";
import { toggleFullScreen } from "sections/ZoomClient/StudentZoomClientPage/helpers";
import { HEADER_HEIGHT } from "sections/ZoomClient/constants";
import { BrowserTabOption } from "sections/ZoomClient/types";
import { getTabListOptions } from "../../helpers";
import { useStudentBrowserData } from "../StudentBrowserProvider";
import { StudentBrowserTab } from "./components/StudentBrowserTab";

export const StudentBrowserHeader = () => {
  const { tabs, setTabs, activeTabIndex, setActiveTabIndex } =
    useStudentBrowserData();
  const { leftWidth, rightWidth, totalWidth, setLeftWidth } =
    useSplitPaneData();
  const { isFullScreen, setIsFullScreen } = useStudentData();
  const [openTabOptions, setOpenTabOptions] = useState(false);
  const [hasOverflowTabs, setHasOverflowTabs] = useState(false);

  const tabsWidth = (rightWidth ?? 0) - 155;
  const containerRef = useRef<HTMLDivElement>(null);
  const isBrowserFull = (leftWidth ?? 0) < 10;

  useEffect(() => {
    const checkContentOverflow = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth } = containerRef.current;
        const isOverflowing = scrollWidth > clientWidth;
        setHasOverflowTabs(isOverflowing);
      }
    };
    checkContentOverflow();
    window.addEventListener("resize", checkContentOverflow);
    return () => window.removeEventListener("resize", checkContentOverflow);
  }, [rightWidth, tabs.length]);

  const renderBrowserTabs = (
    <div
      ref={containerRef}
      className="flex relative w-fit items-end justify-start overflow-hidden gap-1"
      style={{ maxWidth: `${tabsWidth}px`, height: `${HEADER_HEIGHT}px` }}
    >
      {tabs.map((__, i) => (
        <StudentBrowserTab key={i} tabIndex={i} />
      ))}
      {hasOverflowTabs && (
        <div className="w-[80px] h-full absolute right-0 top-0 bg-linear-to-l from-slate-800 via-transparent to-transparent pointer-events-none" />
      )}
    </div>
  );

  const renderBrowserTabActions = (
    <div className="flex relative w-fit h-full items-center justify-start cursor-pointer ml-1">
      <div
        className={clsx(
          "absolute bottom-[-3px] left-[30px] w-fit h-fit",
          !openTabOptions && "pointer-events-none"
        )}
      >
        <SelectMenu
          options={getTabListOptions(tabs)}
          onSelect={(tab: BrowserTabOption) => {
            setOpenTabOptions(false);
            setActiveTabIndex(tab.index);
          }}
          darkMode
          externalOpen={openTabOptions}
          showListOnly
          initialIndex={tabs.length - activeTabIndex - 1}
          listAlignment="right"
          className="w-full min-w-[200px] relative"
        />
      </div>
      {hasOverflowTabs && (
        <div
          className="flex w-fit h-full px-1 items-center cursor-pointer group"
          onClick={() => setOpenTabOptions(!openTabOptions)}
        >
          <Icon
            icon="circleEllipses"
            size={6}
            color="text-slate-300 group-hover:text-white"
          />
        </div>
      )}
      <div
        className="flex w-fit px-1 h-full items-center cursor-pointer group "
        onClick={() => {
          setTabs((prev) => [...prev, { specialTab: "search" }]);
          setActiveTabIndex(tabs.length);
        }}
      >
        <Icon
          icon="add"
          size={6}
          color="text-slate-300 group-hover:text-white"
        />
      </div>
    </div>
  );

  const renderBrowserHeaderActions = (
    <div className="flex justify-between items-center h-full w-fit mr-3 gap-x-1">
      <div
        className="flex w-fit h-full px-1 flex-center cursor-pointer group"
        onClick={() => setLeftWidth(isBrowserFull ? (totalWidth ?? 0) / 2 : 0)}
      >
        <Icon
          icon={isBrowserFull ? "openSidebar" : "closeSidebar"}
          size={6}
          color="text-slate-300 group-hover:text-white"
          className="transform rotate-180"
        />
      </div>
      <div
        className="flex w-auto h-full px-1 flex-center cursor-pointer group"
        onClick={async () => {
          const result = await toggleFullScreen(isFullScreen);
          setIsFullScreen(result);
        }}
      >
        <Icon
          icon={isFullScreen ? "minimize" : "maximize"}
          size={6}
          color="text-slate-300 group-hover:text-white"
        />
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        "absolute left-0 top-0 flex w-full items-center justify-between z-1200 pl-[6px]",
        "bg-slate-800 shadow-sm"
      )}
      style={{ height: `${HEADER_HEIGHT}px` }}
    >
      <div className="flex h-full items-end justify-start w-fit">
        {renderBrowserTabs}
        {renderBrowserTabActions}
      </div>

      {renderBrowserHeaderActions}
    </div>
  );
};
