import { GoogleSearchResult } from "@generated/graphql";
import { Spinner } from "components/shared";
import { useStudentBrowserData } from "../../../StudentBrowserProvider";

type Props = {
  results: GoogleSearchResult[];
  loading: boolean;
};

export const GoogleSearchResults = ({ results, loading = false }: Props) => {
  const { tabs, setTabs, setActiveTabIndex } = useStudentBrowserData();
  return (
    <div className="flex flex-col items-center w-full h-full px-8">
      {loading ? (
        <div className="flex w-full h-full min-h-[200px] flex-center">
          <Spinner size={10} color="text-blue-500" />
        </div>
      ) : (
        results.map((item: GoogleSearchResult, i) => (
          <div key={i}>
            <div
              onClick={() => {
                setTabs([
                  ...tabs,
                  {
                    url: item.link ?? "",
                    name: item.title ?? "",
                  },
                ]);
                setActiveTabIndex(tabs.length);
              }}
              className="text-blue-500 hover:underline font-semibold cursor-pointer"
            >
              {item.title}
            </div>
            <p className="text-gray-700">{item.snippet}</p>
          </div>
        ))
      )}
    </div>
  );
};
