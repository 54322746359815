import { Pixel } from "@utils/numbers";
import clsx from "clsx";
import React, { createRef, useEffect, useRef, useState } from "react";
import { SplitPaneProvider, useSplitPaneData } from "./SplitPaneProvider";

type Props = {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
  initialLeftPaneWidthPercentage?: number;
  minWidth?: Pixel;
};

export const SplitPane = (props: Props) => (
  <SplitPaneProvider>
    <SplitPaneBody {...props} />
  </SplitPaneProvider>
);

function SplitPaneBody({
  leftPane,
  rightPane,
  initialLeftPaneWidthPercentage = 50,
  minWidth = 0,
}: Props) {
  const {
    leftWidth,
    totalWidth,
    height,
    setLeftWidth,
    setTotalWidth,
    setHeight,
  } = useSplitPaneData();

  const [separatorXPosition, setSeparatorXPosition] = useState<
    undefined | number
  >(undefined);
  const [isDragging, setIsDragging] = useState(false);

  const splitPaneRef = createRef<HTMLDivElement>();
  const hadFirstDragRef = useRef<boolean>();

  useEffect(() => {
    if (
      !hadFirstDragRef.current &&
      splitPaneRef.current &&
      initialLeftPaneWidthPercentage >= 0 &&
      initialLeftPaneWidthPercentage <= 100
    ) {
      setLeftWidth(
        (splitPaneRef.current.clientWidth * initialLeftPaneWidthPercentage) /
          100
      );
    }
  }, [splitPaneRef, initialLeftPaneWidthPercentage, leftWidth, setLeftWidth]);

  useEffect(() => {
    if (splitPaneRef.current) {
      if (splitPaneRef.current.clientWidth !== totalWidth)
        setTotalWidth(splitPaneRef.current.clientWidth);
      if (splitPaneRef.current.clientHeight !== height)
        setHeight(splitPaneRef.current.clientHeight);
    }
  }, [splitPaneRef, totalWidth, setTotalWidth, height, setHeight]);

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorXPosition(e.clientX);
    setIsDragging(true);
    hadFirstDragRef.current = true;
  };
  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setIsDragging(true);
    hadFirstDragRef.current = true;
  };

  const onMouseUp = () => setIsDragging(false);
  const onTouchEnd = () => setIsDragging(false);

  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    onMove(e.clientX);
  };
  const onTouchMove = (e: TouchEvent) => onMove(e.touches[0].clientX);
  const onMove = (clientX: number) => {
    if (
      isDragging &&
      leftWidth !== undefined &&
      separatorXPosition !== undefined
    ) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition;
      setSeparatorXPosition(clientX);

      if (newLeftWidth < minWidth) {
        setLeftWidth(minWidth);
        return;
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth;

        if (newLeftWidth > splitPaneWidth - minWidth) {
          setLeftWidth(splitPaneWidth - minWidth);
          return;
        }
      }

      setLeftWidth(newLeftWidth);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchEnd", onTouchEnd);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchEnd", onTouchEnd);
    };
  });

  return (
    <div
      className={clsx(
        "flex flex-row h-full w-full justify-start",
        isDragging && "select-none"
      )}
      ref={splitPaneRef}
    >
      <LeftPane>{leftPane}</LeftPane>
      <div
        className="flex self-stretch items-center px-0 cursor-col-resize"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <div
          className={clsx(
            "h-full w-2",
            isDragging ? "bg-indigo-500" : "bg-slate-500 hover:bg-indigo-600"
          )}
        />
      </div>
      {isDragging && (
        <div
          className="absolute inset-0 z-20 cursor-col-resize"
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
        />
      )}
      <RightPane>{rightPane}</RightPane>
    </div>
  );
}

function LeftPane({ children }: { children: React.ReactNode }) {
  const { leftWidth, setLeftWidth } = useSplitPaneData();
  const leftRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (leftRef.current) {
      if (leftWidth === undefined) {
        setLeftWidth(leftRef.current.clientWidth);
        return;
      }

      leftRef.current.style.width = `${leftWidth}px`;
    }
  }, [leftRef, leftWidth, setLeftWidth]);

  return (
    <div className="z-10" ref={leftRef}>
      {children}
    </div>
  );
}

function RightPane({ children }: { children: React.ReactNode }) {
  const { rightWidth, setRightWidth } = useSplitPaneData();
  const rightRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (rightRef.current && rightRef.current.clientWidth !== rightWidth) {
      setRightWidth(rightRef.current.clientWidth);
    }
  }, [rightRef, rightWidth, setRightWidth]);

  return (
    <div className="flex-1 z-10" ref={rightRef}>
      {children}
    </div>
  );
}
