import clsx from "clsx";
import { Icon } from "components/shared";

type Props = {
  pageOffset: number;
  setPageOffset: (action: React.SetStateAction<number>) => void;
  prevDisabled: boolean;
  nextDisabled: boolean;
};

export function GoogleSearchPaginationControls({
  pageOffset,
  setPageOffset,
  prevDisabled,
  nextDisabled,
}: Props) {
  return (
    <div className="sticky flex bottom-0 mx-auto w-full justify-between items-center h-fit px-6 pb-2">
      <button
        disabled={prevDisabled}
        onClick={() => setPageOffset((prev) => prev - 1)}
        className={clsx(
          "flex relative cursor-pointer items-center border border-slate-400 pr-4 pl-2 py-2",
          " bg-white text-slate-600 text-sm rounded-md font-medium",
          prevDisabled && "bg-gray-100! border-gray-200!"
        )}
      >
        <Icon icon="chevronLeft" size={5} color="text-slate-600" />
        Previous
      </button>
      <span className="text-gray-600 text-sm bg-white px-3 py-2 rounded-full">
        Page {pageOffset + 1}
      </span>
      <button
        disabled={nextDisabled}
        onClick={() => setPageOffset((prev) => prev + 1)}
        className={clsx(
          "flex relative cursor-pointer items-center border border-slate-400 pl-4 pr-2 py-2",
          " bg-white text-slate-600 text-sm rounded-md font-medium ",
          nextDisabled && "bg-gray-100! border-gray-200!"
        )}
      >
        Next
        <Icon icon="chevronRight" size={5} color="text-slate-600" />
      </button>
    </div>
  );
}
