import noop from "lodash/noop";
import React, { createContext, useContext, useState } from "react";
import { BrowserTab } from "sections/ZoomClient/types";

type StudentBrowserContextProps = {
  tabs: BrowserTab[];
  setTabs: React.Dispatch<React.SetStateAction<BrowserTab[]>>;
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  isActiveTab: (tab: number) => boolean;
};

const initialState: StudentBrowserContextProps = {
  tabs: [{ specialTab: "home", icon: "home" }],
  setTabs: noop,
  activeTabIndex: 0,
  setActiveTabIndex: noop,
  isActiveTab: () => false,
};

export const StudentBrowserContext =
  createContext<StudentBrowserContextProps>(initialState);

type Props = {
  children: React.ReactNode;
};

export const StudentBrowserProvider = ({ children }: Props) => {
  const [tabs, setTabs] = useState<BrowserTab[]>(initialState.tabs);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    initialState.activeTabIndex
  );

  const isActiveTab = (tabIndex: number) => tabIndex === activeTabIndex;

  return (
    <StudentBrowserContext.Provider
      value={{
        tabs,
        setTabs,
        activeTabIndex,
        setActiveTabIndex,
        isActiveTab,
      }}
    >
      {children}
    </StudentBrowserContext.Provider>
  );
};

export const useStudentBrowserData = (): StudentBrowserContextProps => {
  const context = useContext(StudentBrowserContext);
  if (context === undefined) {
    throw new Error(
      "useStudentBrowserData must be used within a StudentBrowserProvider"
    );
  }
  return context;
};
