import { Pixel } from "@utils/numbers";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  effectiveHeight: Pixel;
  zIndex: number;
};

export const StudentBrowserTabContainer = ({
  children,
  effectiveHeight,
  zIndex,
}: Props) => (
  <div
    className={clsx(
      "absolute left-0 top-0 flex flex-col w-full z-0 overflow-x-hidden overflow-y-auto bg-white",
      getScrollbarStyle("gray")
    )}
    style={{ zIndex, height: effectiveHeight }}
  >
    {children}
  </div>
);
