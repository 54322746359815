import { gql } from "@apollo/client";
import { VideoClientHomeLink_VideoClientLinkFragment } from "@generated/graphql";
import { getContrastingTextColor } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { IconType } from "components/shared/Icon";
import Image from "next/legacy/image";
import { useState } from "react";
import { VideoClientLinkAction } from "sections/ZoomClient/types";

VideoClientHomeLink.fragments = {
  videoClientLink: gql`
    fragment VideoClientHomeLink_VideoClientLink on VideoClientLink {
      id
      name
      url
      forceExternal
      bgColor
      iconPath
    }
  `,
};

type Props<T extends VideoClientHomeLink_VideoClientLinkFragment> = {
  link: T;
  showIcon?: boolean;
  compact?: boolean;
  disabled?: boolean;
  isAddMode?: boolean;
  addVideoClientLink?: (link: T) => void;
  editVideoClientLink?: (link: T) => void;
  onClick?: () => void;
};

export function VideoClientHomeLink<
  T extends VideoClientHomeLink_VideoClientLinkFragment,
>({
  link,
  showIcon = true,
  compact = false,
  disabled = false,
  isAddMode = false,
  addVideoClientLink,
  editVideoClientLink,
  onClick,
}: Props<T>) {
  const { Edit, Add, Remove } = VideoClientLinkAction;
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverMode, setHoverMode] = useState<VideoClientLinkAction>();

  const getIconDetails = (mode: VideoClientLinkAction) => {
    switch (mode) {
      case Remove:
        return {
          className: "text-rose-500 justify-end bg-rose-500",
          fn: addVideoClientLink,
        };
      case Add:
        return {
          className: "text-blue-500 justify-end bg-blue-500",
          fn: addVideoClientLink,
        };
      default:
      case Edit:
        return {
          className: "text-emerald-500 bg-teal-500",
          fn: editVideoClientLink,
        };
    }
  };

  const getIconContainer = (mode: VideoClientLinkAction) => {
    const { className, fn } = getIconDetails(mode);
    const isHovered = hoverMode === mode;

    return (
      <div
        className={clsx(
          "flex relative h-full w-1/2 p-1 rounded-lg",
          "hover:bg-opacity-[0.4] bg-opacity-0",
          className
        )}
        onMouseOver={() => setHoverMode(mode)}
        onMouseLeave={() => setHoverMode(undefined)}
        onClick={() => fn?.(link)}
      >
        <div
          className={clsx(
            "flex flex-center h-[20px] w-[20px] rounded-full bg-white",
            isHovered ? "opacity-1 transform scale-[1.2]" : "opacity-[0.6]",
            "transition-all duration-100 ease-in-out"
          )}
        >
          <Icon
            icon={mode as IconType}
            size={5}
            color={clsx("!inherit opacity-[0.8]", isHovered && "opacity-1")}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "relative flex gap-x-2 max-h-[20vh] col-span-1 border-2 border-slate-100",
        "flex-center cursor-pointer group p-3 w-full rounded-lg",
        "font-bold hover:border-blue-500",
        getContrastingTextColor(link.bgColor),
        compact ? "h-[50px] rounded-lg] min-w-[120px]" : "h-[150px] rounded-xl"
      )}
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ backgroundColor: link.bgColor }}
      onClick={onClick}
    >
      <Tooltip
        content={
          <div className="flex flex-row gap-x-1">
            {link.url}
            {link.forceExternal && (
              <Icon icon="externalLink" color="text-white" size={5} />
            )}
          </div>
        }
        disabled={!compact}
        className="w-full h-full flex flex-center gap-2"
        tooltipProps={{ place: "right", isOpen: showTooltip }}
      >
        {showIcon && link.iconPath && (
          <Image
            src={`/logos/videoClientLogos/${link.iconPath}`}
            height={compact ? 20 : 35}
            width={compact ? 20 : 35}
            alt="logo"
          />
        )}
        <span
          className={clsx(
            "leading-5 text-center",
            compact ? "text-lg" : showIcon ? "text-2xl" : "text-xl"
          )}
        >
          {link.name}
        </span>
        {link.forceExternal && !compact && (
          <Icon
            icon="externalLink"
            size={6}
            color={getContrastingTextColor(link.bgColor)}
          />
        )}
      </Tooltip>
      {compact && !disabled && (
        <div
          className={clsx(
            "absolute top-0 left-0 flex w-full h-full",
            compact ? "rounded-lg" : "rounded-xl"
          )}
        >
          {getIconContainer(Edit)}
          {getIconContainer(isAddMode ? Add : Remove)}
        </div>
      )}
    </div>
  );
}
