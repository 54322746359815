import {
  Organization,
  StudentMeetingDetails_StudentNextSessionFragment,
} from "@generated/graphql";
import noop from "lodash/noop";
import React, { createContext, useContext, useState } from "react";

export type NextSessionStudent = {
  studentExternalId: string;
  nextSession: StudentMeetingDetails_StudentNextSessionFragment | null;
} | null;

const initState = {
  meetingId: "",
  student: null,
  engagementId: "",
  organizationId: "",
  isFullScreen: false,
  showZoomClient: false,
  organizationShortcut: "",
  externalStudentIdParam: null,
  setStudent: noop,
  setMeetingId: noop,
  setIsFullScreen: noop,
  setEngagementId: noop,
  setShowZoomClient: noop,
};

type StudentDataContext = {
  student: NextSessionStudent;
  meetingId: string;
  engagementId: string;
  isFullScreen: boolean;
  showZoomClient: boolean;
  organizationShortcut: string;
  organizationId: Organization["id"];
  externalStudentIdParam?: string | null;
  setMeetingId: React.Dispatch<React.SetStateAction<string>>;
  setEngagementId: React.Dispatch<React.SetStateAction<string>>;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowZoomClient: React.Dispatch<React.SetStateAction<boolean>>;
  setStudent: React.Dispatch<React.SetStateAction<NextSessionStudent>>;
};

const StudentDataContext = createContext<StudentDataContext>(initState);

export const StudentDataProvider: React.FC<{
  children: React.ReactNode;
  organizationShortcut: string;
  organizationId: Organization["id"];
  externalStudentIdParam?: string | null;
}> = ({
  children,
  organizationId,
  organizationShortcut,
  externalStudentIdParam,
}) => {
  const [showZoomClient, setShowZoomClient] = useState<boolean>(
    initState.showZoomClient
  );
  const [meetingId, setMeetingId] = useState<string>(initState.meetingId);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    initState.isFullScreen
  );
  const [student, setStudent] = useState<NextSessionStudent>(initState.student);
  const [engagementId, setEngagementId] = useState<string>(
    initState.engagementId
  );

  const contextValue: StudentDataContext = {
    student,
    meetingId,
    isFullScreen,
    engagementId,
    organizationId,
    showZoomClient,
    organizationShortcut,
    externalStudentIdParam,
    setStudent,
    setMeetingId,
    setIsFullScreen,
    setEngagementId,
    setShowZoomClient,
  };

  return (
    <StudentDataContext.Provider value={contextValue}>
      {children}
    </StudentDataContext.Provider>
  );
};

export const useStudentData = () => {
  const context = useContext(StudentDataContext);
  if (context === undefined) {
    throw new Error("useStudentData must be used within a StudentDataProvider");
  }
  return context;
};
