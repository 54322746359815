import { gql, useLazyQuery } from "@apollo/client";
import {
  GetCustomGoogleSearchResultsQuery,
  GetCustomGoogleSearchResultsQueryVariables,
  GoogleSearchResult,
  VideoClientLink,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import clsx from "clsx";
import { Icon, TbTLogo } from "components/shared";
import { TbTCheck, TbTCheckVariant } from "components/shared/TbTCheck";
import { TbTLogoVariant } from "components/shared/TbTLogo";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import { useSplitPaneData } from "sections/ZoomClient/StudentZoomClientPage/SplitPane/SplitPaneProvider";
import { getNumberFromString } from "../../../../utils";
import { useStudentBrowserData } from "../../StudentBrowserProvider";
import { GoogleSearchPaginationControls } from "./components/GoogleSearchPaginationControls";
import { GoogleSearchResults } from "./components/GoogleSearchResults";
import { HomeQuickLinks } from "./components/HomeQuickLinks";
import { GOOGLE_SEARCH_PAGE_SIZE } from "./constants";

const CUSTOM_GOOGLE_SEARCH = gql`
  query GetCustomGoogleSearchResults($input: GetGoogleSearchResultsInput!) {
    getGoogleSearchResults(input: $input) {
      results {
        link
        title
        snippet
      }
      totalResults
      searchTime
      error
    }
  }
`;

type Props = {
  quickLinks: VideoClientLink[];
  showGoogleSearch: boolean;
};

export const HomeTab = ({ quickLinks, showGoogleSearch }: Props) => {
  const [query, setQuery] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [searchTime, setSearchTime] = useState("");
  const [totalResults, setTotalResults] = useState("");
  const [results, setResults] = useState<GoogleSearchResult[]>([]);
  const { rightWidth } = useSplitPaneData();
  const { tabs, setTabs, setActiveTabIndex } = useStudentBrowserData();

  const hasResults = results.length > 0;
  const hasQuery = !!query.trim();
  const totalResultsNum = getNumberFromString(totalResults);
  const maxLogoWidth = Math.max(250, 0.15 * (rightWidth ?? 0));
  const logoWidth = clamp((rightWidth ?? 0) - 200, 100, maxLogoWidth);
  const prevDisabled = pageOffset <= 0;
  const nextDisabled = (pageOffset + 1) * 10 >= totalResultsNum;

  const [getGoogleSearchResultsLazy, { loading }] = useLazyQuery<
    GetCustomGoogleSearchResultsQuery,
    GetCustomGoogleSearchResultsQueryVariables
  >(CUSTOM_GOOGLE_SEARCH, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      const { results, totalResults, searchTime } = data.getGoogleSearchResults;
      if (!data.getGoogleSearchResults.error) {
        results && setResults(results);
        totalResults && setTotalResults(totalResults);
        searchTime && setSearchTime(searchTime);
      } else {
        fetchErrToast(
          "Google search results",
          data.getGoogleSearchResults.error
        );
      }
    },
    onError: (error) => console.error(error),
  });

  const handleSearch = async () => {
    if (!query.trim()) return;

    if (query.match(/^https?:\/\//)) {
      setQuery("");
      setTabs([
        ...tabs,
        {
          url: query,
          name: query.replace(/^https?:\/\//, ""),
        },
      ]);
      setActiveTabIndex(tabs.length);
    } else {
      await getGoogleSearchResultsLazy({
        variables: {
          input: {
            query,
            pageOffset,
            pageSize: GOOGLE_SEARCH_PAGE_SIZE,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!hasQuery && hasResults) setResults([]);
  }, [hasQuery, hasResults]);

  return (
    <div
      className={clsx(
        "flex flex-col w-full h-full justify-start relative items-center ",
        hasResults ? "pt-8" : "pt-[10%]"
      )}
    >
      <div className="flex flex-col gap-y-5 justify-start items-center w-fit mb-10">
        <div
          className="flex relative items-center w-fit min-w-20 gap-[3px] h-fit bg-slate-700 rounded-full py-3 pr-2 pl-12 mb-3"
          style={{
            padding: `${logoWidth * 0.05}px ${logoWidth * 0.03}px ${
              logoWidth * 0.05
            }px ${logoWidth * 0.18}px`,
          }}
        >
          <TbTCheck
            size={logoWidth * 0.35}
            background="bg-white"
            variant={TbTCheckVariant.BLUE_SQUARE_FILL}
          />
          <TbTLogo
            width={logoWidth}
            variant={TbTLogoVariant.WHITE_NONE}
            className="mt-[9px]"
          />
        </div>
        {showGoogleSearch && (
          <form
            className="flex flex-row gap-x-0 flex-nowrap"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search or paste a URL..."
              className="flex justify-center items-start border border-gray-300 rounded-l-full px-4 py-4 w-full text-xs lg:text-base pl-7"
            />
            <button
              className="bg-blue-600 cursor-pointer w-16 h-full rounded-r-full"
              onClick={handleSearch}
              type="submit"
            >
              <Icon
                icon="search"
                className="ml-3"
                size={6}
                color="text-white"
              />
              <span className="sr-only">Search</span>
            </button>
          </form>
        )}

        {hasResults && (
          <span className="flex text-gray-600 text-sm py-1">{`About ${totalResults} results in ${searchTime} seconds`}</span>
        )}
      </div>

      {showGoogleSearch && hasResults ? (
        <div className="relative w-full max-w-[900px]">
          <GoogleSearchResults results={results} loading={loading} />
          <GoogleSearchPaginationControls
            pageOffset={pageOffset}
            setPageOffset={(action) => {
              setPageOffset(action);
              handleSearch();
            }}
            prevDisabled={prevDisabled}
            nextDisabled={nextDisabled}
          />
        </div>
      ) : (
        <HomeQuickLinks
          quickLinks={quickLinks}
          showIcon={(rightWidth ?? 0) > 400}
        />
      )}
    </div>
  );
};
