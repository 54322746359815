import { Pixel } from "@utils/numbers";

type Props = {
  title: string;
  videoId: string;
  width: Pixel;
  height: Pixel;
};

export const YouTubeEmbed = ({ title, videoId, width, height }: Props) => (
  <iframe
    title={title}
    src={`https://www.youtube.com/embed/${videoId}`}
    width={width}
    height={height}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    // https://developer.mozilla.org/en-US/docs/Web/Security/IFrame_credentialless
    credentialless="true"
  />
);
