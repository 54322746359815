import { Transition } from "@headlessui/react";
import { openInNewTab } from "@utils/browser";
import { SECOND_MS } from "@utils/dateTime";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { Icon } from "components/shared";
import { useEffect, useState } from "react";

type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

type Props = {
  src: string;
  position: Position;
};

export function ExternalLinkBar({ src, position }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapsed(true);
    }, 4 * SECOND_MS);

    return () => clearTimeout(timer);
  }, []);

  const onClick = () => openInNewTab(src);

  return (
    <div
      className={clsx(
        POSITION_CLASSES[position],
        "absolute h-8 transition-opacity duration-150",
        isCollapsed ? "opacity-40 hover:opacity-100" : "opacity-100"
      )}
    >
      <Transition
        show={!isCollapsed}
        enter="transition ease-in-out duration-500 transform"
        enterFrom={getTranslateClass(position).collapsed}
        enterTo={getTranslateClass(position).expanded}
        leave="transition ease-in-out duration-500 transform"
        leaveFrom={getTranslateClass(position).expanded}
        leaveTo={getTranslateClass(position).collapsed}
      >
        <div
          onClick={onClick}
          className={clsx(
            "bg-white p-2 text-sm shadow-sm h-full cursor-pointer",
            ROUNDED_CLASSES[position]
          )}
        >
          <span
            className={clsx(
              "text-nowrap overflow-hidden",
              getMarginClass(position)
            )}
          >
            Trouble loading page? Click here...
          </span>
        </div>
      </Transition>
      <div
        onClick={onClick}
        className={clsx(
          "absolute flex flex-center",
          "bg-white h-full w-8 cursor-pointer",
          POSITION_CLASSES[position],
          ROUNDED_CLASSES[position],
          "transition duration-500",
          isCollapsed && "shadow-sm"
        )}
      >
        <Icon icon="externalLink" />
      </div>
    </div>
  );
}

const POSITION_CLASSES = {
  "top-left": "top-0 left-0",
  "top-right": "top-0 right-0",
  "bottom-left": "bottom-0 left-0",
  "bottom-right": "bottom-0 right-0",
};

const ROUNDED_CLASSES = {
  "top-left": "rounded-br-lg",
  "top-right": "rounded-bl-lg",
  "bottom-left": "rounded-tr-lg",
  "bottom-right": "rounded-tl-lg",
};

const getTranslateClass = (
  position: Position
): { collapsed: string; expanded: string } => {
  switch (position) {
    case "top-left":
    case "bottom-left":
      return {
        collapsed: "-translate-x-full",
        expanded: "translate-x-0",
      };
    case "top-right":
    case "bottom-right":
      return {
        collapsed: "translate-x-full",
        expanded: "translate-x-0",
      };
    default:
      assertUnreachable(position, "position");
  }
};

const getMarginClass = (position: Position): string => {
  switch (position) {
    case "top-left":
    case "bottom-left":
      return "ml-8";
    case "top-right":
    case "bottom-right":
      return "mr-8";
    default:
      assertUnreachable(position, "position");
  }
};
