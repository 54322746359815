import { VideoClientLink, ZoomClientConfiguration } from "@generated/graphql";
import { SelectMenuOption } from "components/shared";

export const HEADER_HEIGHT = 50;

export const NEW_CONFIG_INDEX = 1;
export const DEFAULT_CONFIG_INDEX = 0;

export const NEW_CONFIG_ID = "-2";
export const DEFAULT_CONFIG_ID = null;

export const DEFAULT_CONFIG_NAME = "Default Configuration";

export const previewDeviceOptions: SelectMenuOption<{
  aspectRatio: number;
}>[] = [
  { id: "0", value: "Chromebook (3:2 Aspect Ratio)", aspectRatio: 3 / 2 },
  { id: "1", value: "iPad (4:3 Aspect Ratio)", aspectRatio: 4 / 3 },
];

export const defaultVideoClientLinks: VideoClientLink[] = [
  {
    id: "1000",
    name: "Freckle",
    bgColor: "#A47398",
    url: "https://student.freckle.com/#/login",
    iconPath: "freckleLogo.svg",
    isActivelyUsed: true,
    forceExternal: false,
  },
  {
    id: "1001",
    name: "Classkick",
    bgColor: "#73A480",
    url: "https://app.classkick.com/#/login",
    iconPath: "classKickLogo.png",
    isActivelyUsed: true,
    forceExternal: false,
  },
  {
    id: "1002",
    name: "Nearpod",
    bgColor: "#739CA4",
    url: "https://nearpod.com/login/",
    iconPath: "nearPodLogo.png",
    isActivelyUsed: true,
    forceExternal: true,
  },
  {
    id: "1003",
    name: "Desmos",
    bgColor: "#7380A4",
    url: "https://student.desmos.com/?r=w.hd",
    iconPath: "desmosLogo.png",
    isActivelyUsed: true,
    forceExternal: false,
  },
];

export const defaultZoomConfig: ZoomClientConfiguration = {
  id: "-1",
  name: DEFAULT_CONFIG_NAME,
  description:
    "Standard client settings which to be used a default for all, unless a custom configuration is selected",
  showGoogleSearch: true,
  initialZoomWidthPercent: 50,
  linkedEngagements: [],
  videoClientLinkIds: defaultVideoClientLinks.map(({ id }) => id),
  videoClientLinks: defaultVideoClientLinks,
};

export const initialNewConfig: ZoomClientConfiguration = {
  id: NEW_CONFIG_ID,
  name: "",
  description: "",
  showGoogleSearch: true,
  initialZoomWidthPercent: 50,
  linkedEngagements: [],
  videoClientLinkIds: [],
  videoClientLinks: [],
};
