import { Pixel } from "@utils/numbers";
import { useSplitPaneData } from "sections/ZoomClient/StudentZoomClientPage/SplitPane/SplitPaneProvider";
import { ExternalLinkBar } from "./components/ExternalLinkBar";
import { YouTubeEmbed, extractYouTubeVideoId } from "./components/YouTubeEmbed";

type Props = {
  title: string;
  effectiveHeight: Pixel;
  src?: string;
};

export function StudentBrowserIFrame({ effectiveHeight, title, src }: Props) {
  const { rightWidth } = useSplitPaneData();

  const videoId = src ? extractYouTubeVideoId(src) : null;

  return (
    <div className="relative w-full h-full">
      {src && (
        <ExternalLinkBar
          src={src}
          position={videoId ? "top-right" : "bottom-left"}
        />
      )}

      {videoId ? (
        <YouTubeEmbed
          title={title}
          videoId={videoId}
          width={rightWidth ?? 0}
          height={effectiveHeight}
        />
      ) : (
        <iframe
          title={title}
          src={src}
          width={rightWidth ?? 0}
          height={effectiveHeight}
          // https://developer.mozilla.org/en-US/docs/Web/Security/IFrame_credentialless
          credentialless="true"
        />
      )}
    </div>
  );
}
